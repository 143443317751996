import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import ProfilePreload from "@/presentation/components/preloader/profile-preload/ProfilePreload.vue";
import transform from "@/commons/TransformUtil";
import LanguageUtil from "@/commons/LanguageUtil";
export default defineComponent({
    name: "OrganizationProfileInfo",
    components: {
        ProfilePreload: ProfilePreload
    },
    props: ["organizationInfo", "breadcrumb", "preload"],
    setup: function (props) {
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang
        });
        onMounted(function () {
            props.breadcrumb[2] = { name: "基本情報", route: "" };
        });
        return {
            state: state,
            props: props,
            transform: transform
        };
    }
});
