<template>
  <div class="info">
    <ProfilePreload v-if="props.preload"/>
    <div v-else class="basic_info">
      <div class="basic_info__row">
        <label>{{state.aLang.other_organization_info_title_name}}</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.name }"
        >{{ props.organizationInfo.name || state.aLang.other_organization_info_no_data }}</div>
      </div>
      <div class="basic_info__row">
        <label>{{state.aLang.other_organization_info_title_name_kana}}</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.name_kana }"
        >{{ props.organizationInfo.name_kana || state.aLang.other_organization_info_no_data }}</div>
      </div>
      <div class="basic_info__row">
        <label>{{state.aLang.other_organization_info_title_industries}}</label>
        <div v-if="props.organizationInfo.industries.length" class="content">
          <span v-for="(industy, i ) in props.organizationInfo.industries" :key="i" class="industry">
            {{ industy.name }}
          </span>
          <span v-if="props.organizationInfo.industry_optional">{{ props.organizationInfo.industry_optional }}</span>
        </div>
        <div v-else class="content no-data">
          {{ state.aLang.other_organization_info_no_data }}
        </div>
      </div>
      <div class="basic_info__row">
        <label>{{state.aLang.other_organization_info_title_office}}</label>
        <div class="content">
          <span
            v-if="props.organizationInfo.prefecture_name || props.organizationInfo.city_name"
          >{{props.organizationInfo.prefecture_name}}</span>
          <span
            v-if="props.organizationInfo.prefecture_name || props.organizationInfo.city_name"
          ><span v-if="props.organizationInfo.prefecture_name">&nbsp;&nbsp;</span>{{props.organizationInfo.city_name}}</span>
          <span
            class="content"
            v-else
            v-bind:class="{ 'no-data': !props.organizationInfo.prefecture_name && !props.organizationInfo.city_name }"
          >{{state.aLang.other_organization_info_no_data}}</span>
        </div>
      </div>

      <div class="basic_info__row">
        <label>{{state.aLang.other_organization_info_title_company_introduction}}</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.profile_description }"
        >{{ props.organizationInfo.profile_description || state.aLang.other_organization_info_no_data }}</div>
      </div>
      <div class="basic_info__row">
        <label>{{state.aLang.other_organization_info_title_ai_quest}}</label>
        <div
          class="content"
          v-bind:class="{ 'no-data': !props.organizationInfo.profile_ai_description }"
        >{{ props.organizationInfo.profile_ai_description || state.aLang.other_organization_info_no_data }}</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="@/presentation/views/organization/other-profile/info/Info.ts" />
<style lang="scss" src="@/presentation/views/organization/other-profile/info/Info.scss" scoped></style>
